import { MdOutlineStar } from "react-icons/md";

function ReviewSlide({ title, review, person }) {
  return (
    <div className="col-10 col-md-9 col-lg-9 col-xl-7 mx-auto text-light">
      <h2 className="mb-3">
        <MdOutlineStar /> <MdOutlineStar /> <MdOutlineStar /> <MdOutlineStar />{" "}
        <MdOutlineStar />
      </h2>
      <h2 className="mb-4">{title}</h2>
      <p className="fs-3 mb-0">
        {review}
        <br />- {person}
      </p>
    </div>
  );
}

export default ReviewSlide;
