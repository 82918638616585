import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import logo from "../images/majestic-logo.png";
import Button from "react-bootstrap/Button";
import Offcanvas from "react-bootstrap/Offcanvas";
import { FiChevronDown } from "react-icons/fi";

function Header() {
  const [navbarBackground, setNavbarBackground] = useState("transparent");
  const [logoSize, setLogoSize] = useState("navLogo");
  const [changeHeight, setChangeHeight] = useState("110px");

  useEffect(() => {
    const handleScroll = () => {
      if (window.scrollY > 50) {
        setNavbarBackground("rgba(50, 50, 50,.6");
        setChangeHeight("70px");
        setLogoSize("navLogoSmall");
      } else {
        setNavbarBackground("transparent");
        setLogoSize("navLogo");
        setChangeHeight("110px");
      }
    };
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const location = useLocation();
  let style = {};
  let style2 = {};
  let bookLocation =
    "https://atlantisadventures.rezgo.com/details/204130/waikiki-sunset-cocktail-cruise/?promo=20sunsetwebsite";

  if (location.pathname.includes("fireworks")) {
    bookLocation =
      "https://atlantisadventures.rezgo.com/details/205347/friday-fireworks-cocktail-cruise";
  }
  if (location.pathname.includes("whale")) {
    bookLocation =
      "https://atlantisadventures.rezgo.com/details/154748/whale-watch-cruise-in-waikiki";
  }
  // if (location.pathname.includes("luau")) {
  //   bookLocation =
  //     "https://atlantisadventures.rezgo.com/details/361028/majestic-ocean-cruise-and-luau-combo";
  // }
  if (location.pathname.includes("parking")) {
    bookLocation = "";
    style = { visibility: "hidden" };
    style2 = { display: "none" };
  }

  const [language, setLanguage] = useState(false);

  const scrollDown = () => {
    const element = document.getElementById("book");
    if (element) {
      element.scrollIntoView({ behavior: "smooth" });
    }
    setLanguage(false);
  };

  return (
    <>
      {/* <div
        className="position-fixed vw-100 text-center ticker"
        style={{ backgroundColor: "#124789", zIndex: "100" }}
      >
        <p className="text-white fs-5 fst-italic lh-sm bannerText px-1 pt-2 pt-sm-0 pb-0">
          Explore Our New Year's Eve Cruise{" "}
          <a
            style={{ color: "#fff" }}
            href="https://atlantisadventures.rezgo.com/details/323407/majestic-new-years-eve-cruise"
          >
            Click for Details
          </a>
        </p>
      </div> */}
      <div className="languageBar position-fixed vw-100 d-flex justify-content-end pe-xxl-5 pe-lg-4 pe-2">
        <Button
          className="p-0 me-4 text-decoration-none text-white"
          variant="link"
          as={Link}
          to="/parking"
        >
          DIRECTIONS
        </Button>

        <Button
          className="p-0 text-decoration-none text-white"
          variant="link"
          onClick={() => setLanguage(!language)}
        >
          ENGLISH <FiChevronDown style={{ marginBottom: "2px" }} />
        </Button>
        {language && (
          <div
            className="position-absolute jpBar"
            style={{
              top: "25px",
            }}
          >
            <Button
              className="pt-0 text-decoration-none text-white"
              variant="link"
              href="https://jp.atlantisadventures.com"
              onClick={() => setLanguage(false)}
              style={{ padding: "0 24px 2px" }}
            >
              日本語
            </Button>
          </div>
        )}
      </div>
      <Navbar
        collapseOnSelect
        variant="dark"
        sticky="top"
        expand="lg"
        className="position-fixed vw-100 text-center navPos"
        style={{
          backgroundColor: navbarBackground,
          height: changeHeight,
        }}
      >
        <Navbar.Toggle
          aria-controls="responsive-navbar-nav"
          onClick={() => setLanguage(false)}
        />
        <Navbar.Brand
          className="navbar-brand-secondary"
          as={Link}
          to="/"
          onClick={() => setLanguage(false)}
        >
          <img src={logo} className="navLogo-secondary" alt="logo" />
        </Navbar.Brand>

        {location.pathname.includes("charters") ? (
          <Button
            className="booknow-secondary btn-sm position-relative text-nowrap"
            onClick={scrollDown}
          >
            BOOK NOW
          </Button>
        ) : (
          <Button
            className="booknow-secondary btn-sm position-relative text-nowrap"
            style={style}
            href={bookLocation}
            onClick={() => setLanguage(false)}
          >
            BOOK NOW
          </Button>
        )}
        <Navbar.Offcanvas>
          <Offcanvas.Header
            data-bs-theme="dark"
            closeButton
            className="btn-close-white"
          ></Offcanvas.Header>
          <Nav className="justify-content-start justify-content-lg-evenly align-items-lg-center mt-5 mt-lg-0 mx-4 mx-lg-0">
            <Navbar.Brand
              className="navbar-brand-main"
              onClick={() => setLanguage(false)}
              as={Link}
              to="/"
            >
              <img src={logo} className={logoSize} alt="logo" />
            </Navbar.Brand>
            <Nav.Link
              as={Link}
              eventKey="1"
              to="/"
              className="text-nowrap navFontSize my-1 my-lg-0"
              onClick={() => setLanguage(false)}
            >
              WAIKIKI SUNSET
              <br />
              COCKTAIL CRUISE
            </Nav.Link>
            <Nav.Link
              as={Link}
              eventKey="2"
              to="/friday-fireworks-cocktail-cruise"
              className="text-nowrap navFontSize my-1 my-lg-0"
              onClick={() => setLanguage(false)}
            >
              FRIDAY FIREWORKS &<br />
              COCKTAIL CRUISE
            </Nav.Link>
            <Nav.Link
              as={Link}
              eventKey="3"
              to="/whalewatch"
              className="text-nowrap navFontSize my-1 my-lg-0"
              onClick={() => setLanguage(false)}
            >
              WHALE WATCH
              <br />
              CRUISE
            </Nav.Link>
            {/* <Nav.Link
              as={Link}
              eventKey="4"
              to="/luau-combo"
              className="text-nowrap navFontSize my-1 my-lg-0"
              onClick={() => setLanguage(false)}
            >
              COCKTAIL CRUISE &
              <br />
              LUAU PACKAGE
            </Nav.Link> */}
            <Nav.Link
              as={Link}
              eventKey="5"
              to="/boat-charters"
              className="text-nowrap navFontSize my-1 my-lg-0"
              onClick={() => setLanguage(false)}
            >
              GROUP EVENTS &<br />
              CHARTERS
            </Nav.Link>
            {location.pathname.includes("charters") ? (
              <Button
                className="booknow-main text-nowrap navFontSize"
                onClick={scrollDown}
              >
                BOOK NOW
              </Button>
            ) : (
              <Button
                className="booknow-main text-nowrap navFontSize"
                style={style2}
                href={bookLocation}
                onClick={() => setLanguage(false)}
              >
                BOOK NOW
              </Button>
            )}
          </Nav>
        </Navbar.Offcanvas>
      </Navbar>
    </>
  );
}

export default Header;
